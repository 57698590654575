import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import image from '../assets/telegram.svg'; // Ensure the image is in the correct path

const Finish = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Show toast notification when the component is mounted
    toast.info('Proses sedang berlangsung. Silakan tunggu hasil verifikasi.', {
      position: 'top-center',
      autoClose: 5000,
      hideProgressBar: true,
      className: 'bg-gray-800 text-white',
    });
  }, []);

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-900">
      <div className="bg-gray-800 p-8 rounded-lg shadow-lg w-full max-w-md text-center">
        {/* Header Image */}
        <div className="mb-6 text-center">
          <img
            src={image} // Use the imported header image
            alt="Proses Verifikasi"
            className="h-48 w-auto mx-auto rounded-lg"
          />
        </div>

        {/* Title and Subtitle */}
        <h2 className="text-2xl font-bold text-white mb-2">Verifikasi Sedang Berlangsung</h2>
        <p className="text-gray-400 mb-4">
          Permintaan Anda sedang diproses. Silakan tunggu.
        </p>

        {/* Loader Animation */}
        <div className="flex items-center justify-center mb-4">
          <div className="border-t-transparent border-solid border-white border-4 rounded-full h-12 w-12 animate-spin"></div>
        </div>

        {/* Additional Message */}
        <p className="text-gray-300 mb-6">
          Silakan tunggu sementara permintaan Anda sedang diperiksa oleh administrator.
        </p>

        {/* Back Button */}
        <button
          onClick={() => navigate('#')} // Replace '/' with the actual route you want to navigate to
          className="w-full bg-cyan-500 text-white font-semibold py-2 rounded-lg hover:bg-cyan-600 focus:outline-none focus:ring-2 focus:ring-cyan-500 transition-all duration-300"
        >
          &larr; Kembali
        </button>
      </div>
      <ToastContainer /> {/* Include ToastContainer for notifications */}
    </div>
  );
};

export default Finish;
